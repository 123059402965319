import { serverConstants, localhostConstants } from "../utils/constants/serverConstants";

export var service = {};

service.API_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).url
  : localhostConstants.url;

service.BOT_API_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).botBackUrl
  : localhostConstants.botBackUrl;

service.BOT_WEB_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).botFrontUrl
  : localhostConstants.botFrontUrl;

  service.Frontend_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).frontendUrl
  : localhostConstants.frontendUrl;

export default service;
